<template>
  <v-card flat>
    <v-card-actions class="d-print-none">
      <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
      >
        <v-date-picker
            v-model="datePickerDate"
            range
            no-title
            @close="closeDatePicker()"
            @change="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
      <v-text-field :value="datesFormatted"
                    label="Select dates"
                    single-line
                    hide-details
                    readonly
                    @click="showDatePicker=true"
                    append-icon="mdi-calendar"
                    :loading="loading"
      />
      <v-spacer/>
      <v-text-field
          label="Search"
          v-model="search"
          single-line
          hide-details
          clearable
          append-icon="mdi-magnify"
      />
      <v-spacer/>
      <v-select
          label="Filter by Reason"
          v-model="reasonFilter"
          :items="reasons"
          clearable
          multiple
          single-line
          hide-details
      />
      <v-spacer/>
      <v-btn :disabled="loading" outlined @click="csvExport(dataAsTable,exportFilename())">Download CSV</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
          :loading="loading"
          :items="recordsFiltered"
          :headers="headers"
          :search="search"
      >
        <template v-slot:item.stats.net="{item}">
          {{ item.stats && formatCurrency(item.stats.net) }}
        </template>

        <template v-slot:item.metrics.tods="{item}">
          {{ item.metrics && item.metrics.tods && Object.entries(item.metrics.tods).join('\n') }}
        </template>
        <template v-slot:item.metrics.order_type="{item}">
          {{ item.metrics && item.metrics.order_type && Object.entries(item.metrics.order_type).join('\n') }}
        </template>
        <template v-slot:item.metrics.shippingDays="{item}">
          {{ item.metrics && item.metrics.shippingDays && Object.entries(item.metrics.shippingDays).join('\n') }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>

<script>
import api from "@/api";
import moment from "moment";
import {csvAsTable, csvExport, formatCurrency} from "../store/utils";
import urlState from "@/router/urlState";

export default {
  name: "CustomerCancelReasons",
  mixins: [urlState],
  data() {
    return {
      dates: [],
      showDatePicker: null,
      search: null,
      datePickerDate: null,
      records: [],
      loading: false,
      headers: (
          'date reason ratings.overall ratings.meals ratings.delivery ratings.service explanation email plan ' +
          'stats.firstCharge stats.net ' +
          'metrics.weeks ' +
          // 'metrics.lastUpdate metrics.tods metrics.order_type metrics.shipments metrics.shippingDays metrics.meals' +
          // 'stats.total stats.last stats.lastCharge stats.invoiceCount  stats.first stats.refunds'
          ''
      )
          .split(' ')
          .filter(s => !!s)
          .map(k => ({value: k, text: k.replace('.',' ')})),
      reasonFilter: null
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'reasonFilter', urlParam: 'reasons', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.$nextTick(() => {
      if (!this.dates || this.dates.length !== 2) {
        this.dates = [
          moment().format(moment.HTML5_FMT.DATE),
          moment().subtract(3, 'weeks').format(moment.HTML5_FMT.DATE)
        ]
      }
      this.fetchData();
    });
  },
  watch: {
    dates: 'fetchData',
    showDatePicker(v) {
      if (v) {
        this.datePickerDate = this.dates;
      } else {
        this.dates = this.datePickerDate;
      }
    },
  },
  methods: {
    formatCurrency,
    async fetchData() {
      this.loading = true;
      await api.get('v2/customers/close-feedback', {params: {from: this.dateFrom, to: this.dateTo}})
          .then(r => this.records = r.data);
      this.loading = false;
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    csvExport,
    exportFilename() {
      if (this.exportFile) {
        return this.exportFile;
      }
      const parts = [...this.dates, this.search].filter(s => !!s);
      return `cancel-reasons-${parts.join('-')}`;
    },
  },
  computed: {
    dataAsTable() {
      return csvAsTable(this.recordsFiltered, this.headers);
    },
    reasons() {
      const reasons = new Set(this.records.map(r => r.reason));
      if (this.reasonFilter) {
        this.reasonFilter.forEach(r => reasons.add(r));
      }
      return [...reasons].sort();
    },
    recordsFiltered() {
      let records = this.records;
      if (this.reasonFilter && this.reasonFilter.length > 0) {
        records = records.filter(r => this.reasonFilter.includes(r.reason));
      }
      return records;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
  }
}
</script>

