import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardActions,{staticClass:"d-print-none"},[_c(VDialog,{ref:"dialog",attrs:{"width":"290px","persistent":""},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"range":"","no-title":""},on:{"close":function($event){return _vm.closeDatePicker()},"change":_vm.closeDatePicker},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}}),_c(VBtn,{on:{"click":_vm.closeDatePicker}},[_vm._v("Close")])],1),_c(VTextField,{attrs:{"value":_vm.datesFormatted,"label":"Select dates","single-line":"","hide-details":"","readonly":"","append-icon":"mdi-calendar","loading":_vm.loading},on:{"click":function($event){_vm.showDatePicker=true}}}),_c(VSpacer),_c(VTextField,{attrs:{"label":"Search","single-line":"","hide-details":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSelect,{attrs:{"label":"Filter by Reason","items":_vm.reasons,"clearable":"","multiple":"","single-line":"","hide-details":""},model:{value:(_vm.reasonFilter),callback:function ($$v) {_vm.reasonFilter=$$v},expression:"reasonFilter"}}),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"outlined":""},on:{"click":function($event){_vm.csvExport(_vm.dataAsTable,_vm.exportFilename())}}},[_vm._v("Download CSV")])],1),_c(VCardText,[_c(VDataTable,{attrs:{"loading":_vm.loading,"items":_vm.recordsFiltered,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.stats.net",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stats && _vm.formatCurrency(item.stats.net))+" ")]}},{key:"item.metrics.tods",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metrics && item.metrics.tods && Object.entries(item.metrics.tods).join('\n'))+" ")]}},{key:"item.metrics.order_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metrics && item.metrics.order_type && Object.entries(item.metrics.order_type).join('\n'))+" ")]}},{key:"item.metrics.shippingDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metrics && item.metrics.shippingDays && Object.entries(item.metrics.shippingDays).join('\n'))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }